.real-time_chat-container{
position: relative;
}

.real-time_chat {
  max-width: 1440px;
  width: 100%;
  height: 623px;
  display: flex;
  gap: 10px;
}

@media screen and (max-width:600px) {
  .real-time_chat{
    justify-content: center;
  }
}